import Detail from "./Detail";
import { Link } from "react-router-dom";

export default function CustomerTile({ customer, title }) {
  return (
    <div
      className="bg-white border rounded p-3 mb-3"
      style={{ minWidth: "400px" }}
    >
      {title && <h3>{title}</h3>}
      <Detail
        title="Heiti"
        value={<Link to={`/customers/${customer.kt}`}>{customer.name}</Link>}
      />
      <Detail
        title="Heimilisfang"
        value={
          <>
            {customer.address}
            <br />
            {customer.post_code
              ? `${customer.post_code} ${customer.post_code_entry.name}`
              : ""}
          </>
        }
      />
    </div>
  );
}
