import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import Dropdown from "react-bootstrap/Dropdown";
import { Status, Translations } from "../../utils/status";
import { priorities } from "../../utils/priority";
import { roles } from "../../authConfig";
import RoleRequired from "../../components/auth/RoleRequired";

export default function TicketUpdate({ ticketId, status, priority }) {
  const intPriority = parseInt(priority);
  const queryClient = useQueryClient();

  const mutation = useAPIMutation("tickets/", {
    method: "patch",
    extraUrl: "ticketId",
    onSuccess: (data) => queryClient.setQueryData(["ticket", ticketId], data),
  });

  const updateStatus = (e) => {
    mutation.mutate({ ticketId, status: e.target.dataset.status });
  };

  const updatePriority = (e) => {
    mutation.mutate({
      ticketId,
      priority: e.target.dataset.priority,
    });
  };

  return (
    <>
      <div>
        <Dropdown>
          <Dropdown.Toggle disabled={mutation.isLoading}>
            Breyta stöðu
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(Status).map((key) => (
              <Dropdown.Item
                as="button"
                data-status={Status[key]}
                onClick={updateStatus}
                key={Status[key]}
                type="button"
                disabled={Status[key] === status}
              >
                {Translations[key]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <RoleRequired requiredRole={[roles.Admin, roles.Manager]}>
        <div>
          <Dropdown className="ms-3">
            <Dropdown.Toggle disabled={mutation.isLoading}>
              Breyta forgangi
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {priorities.map((name, value) => (
                <Dropdown.Item
                  as="button"
                  data-priority={value}
                  onClick={updatePriority}
                  key={value}
                  type="button"
                  disabled={value === intPriority}
                >
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </RoleRequired>
    </>
  );
}
