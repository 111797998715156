import Detail from "./Detail";

export default function CustomerTile({ contact, title }) {
  return (
    <div
      className="bg-white border rounded p-3 mb-3"
      style={{ minWidth: "400px" }}
    >
      {title && <h3>{title}</h3>}
      <Detail title="Nafn" value={contact.name} />
      {contact.kt && <Detail title="Kennitala" value={contact.kt} />}
      {contact.email && (
        <Detail
          title="tölvupóstfang"
          value={<a href={"mailto:" + contact.email}>{contact.email}</a>}
        />
      )}
      {contact.mobile && (
        <Detail
          title="Sími"
          value={<a href={"tel:" + contact.mobile}>{contact.mobile}</a>}
        />
      )}
    </div>
  );
}
