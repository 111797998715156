import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";

export default function CreateInteractionDialog({ show, ticketId, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { success: false, message: "" } });

  const success = watch("success");

  const close = () => {
    reset();
    onClose();
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useAPIMutation(
    "tickets/interactions/",
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["ticket", ticketId], (oldTicket) => ({
          ...oldTicket,
          interactions: [...oldTicket.interactions, data],
        }));
        close();
      },
    }
  );

  const onSubmit = (data) => {
    mutate({ ...data, ticket_id: ticketId, type: "call" });
  };

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>Skrá símtal</Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{error.message}</div>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="interactionSuccess"
                {...register("success")}
                disabled={isLoading}
              />
              <label htmlFor="interactionSuccess" className="form-check-label">
                Símtali svarað
              </label>
            </div>
          </div>
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              placeholder="Niðurstaða símtals"
              style={{ height: "6em" }}
              {...register("message", {
                validate: (v) => !success || v !== "",
              })}
              disabled={!success || isLoading}
            ></textarea>
            <label>Niðurstaða símtals</label>
            {errors.message && (
              <p className="alert alert-warning">Skilaboð vantar</p>
            )}
          </div>
          <div>
            <button
              className="btn btn-success"
              disabled={isLoading}
              type="submit"
            >
              Vista
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
