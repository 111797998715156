import { Outlet } from "react-router-dom";

export default function Intakes() {
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-ui-checks pe-3"></i>Móttökur
      </h1>
      <Outlet />
    </>
  );
}
