import { Link } from "react-router-dom";

export default function ServiceLocationnavBar() {
  return (
    <div className="d-flex mb-4">
      <Link className="btn btn-primary" to="create">
        <i className="bi bi-plus"></i> Stofna tæki
      </Link>
    </div>
  );
}
