import { useAPIQuery, useAPIMutation } from "../../hooks/api-hooks";
import { useNavigate, useParams } from "react-router-dom";
import ContactForm from "../../components/forms/ContactForm";

export default function ContactUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const query = useAPIQuery(`contacts/${id}`, { queryKey: ["contacts", id] });

  const mutation = useAPIMutation(`contacts/${id}`, {
    method: "put",
    onSuccess: (data) => {
      navigate(`/contacts/${data.id}`);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({
      ...data,
      customer_kt: query.data.customer_kt,
      email: data.email || null,
    });
  };

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  return (
    <div className="row">
      <div className="col-4">
        <ContactForm
          disabled={mutation.isLoading}
          onSubmit={onSubmit}
          contact={query.data}
        />
      </div>
    </div>
  );
}
