import TicketColumn from "./TicketColumn";
import { Status } from "../../utils/status";
import { useAPIQuery } from "../../hooks/api-hooks";
import { useLocalStorage } from "../../hooks/uselocalstorage";

export default function TicketSorter() {
  const query = useAPIQuery("service_locations/", {
    queryKey: "servicelocations",
  });

  const [serviceLocationId, setServiceLocationId] = useLocalStorage(
    "serviceLocation",
    null
  );

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    return <div className="alert alert-warning">{query.error.message}</div>;
  }

  if (query.data && !serviceLocationId) {
    setServiceLocationId(query.data[0].id);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3">
          <div className="form-floating">
            <select
              className="form-select"
              value={serviceLocationId}
              onChange={(e) => setServiceLocationId(e.target.value)}
            >
              {query.data.map((serviceLocation) => (
                <option key={serviceLocation.id} value={serviceLocation.id}>
                  {serviceLocation.name}
                </option>
              ))}
            </select>
            <label>Móttökustaður</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col p-3 ">
          <TicketColumn
            title="Nýtt"
            status={Status.NEW}
            serviceLocationId={serviceLocationId}
          />
        </div>
        <div className="col p-3 ">
          <TicketColumn
            title="Bíður"
            status={Status.WAITING}
            serviceLocationId={serviceLocationId}
          />
        </div>
        <div className="col p-3 ">
          <TicketColumn
            title="Í vinnslu"
            status={Status.IN_PROGRESS}
            serviceLocationId={serviceLocationId}
          />
        </div>
        <div className="col p-3 ">
          <TicketColumn
            title="Vinnu lokið"
            status={Status.WORK_COMPLETE}
            serviceLocationId={serviceLocationId}
            interactions
          />
        </div>
      </div>
    </div>
  );
}
