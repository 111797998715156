import { Link, NavLink, Outlet } from "react-router-dom";
import GlobalSearch from "../../components/GlobalSearch";
import ProfileDisplay from "../../components/ProfileDisplay";

export default function Root() {
  return (
    <div className="container-fluid min-vh-100">
      <div className="row" style={{ borderBottom: "1px solid #cbd0dd" }}>
        <div className="col-1 py-2 fs-3">Skírnir</div>
        <div className="col py-2 d-flex align-items-center">
          <GlobalSearch />
          <ProfileDisplay />
        </div>
      </div>
      <div className="d-flex min-vh-100">
        <div
          className="h-100 pe-5 min-vh-100"
          style={{ borderRight: "1px solid #cbd0dd" }}
        >
          <ul className="nav flex-column">
            <li className="nav-item mb-3">
              <NavLink className="nav-link" to="/">
                <i className="bi bi-house-door pe-3"></i>Heim
              </NavLink>
            </li>
            <li className="nav-item mb-3 ms-3">
              <Link className="btn btn-primary" to="/intakes/create">
                <i className="bi bi-plus"></i> Ný móttaka
              </Link>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/tickets">
                <i className="bi bi-clipboard pe-3"></i>Verk
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/intakes">
                <i className="bi bi-ui-checks pe-3"></i>Móttökur
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/customers">
                <i className="bi bi-building pe-3"></i>Viðskiptavinir
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contacts">
                <i className="bi bi-person pe-3"></i>Tengiliðir
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/equipment">
                <i className="bi bi-wrench-adjustable-circle pe-3"></i>Tæki
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/manufacturers">
                <i className="bi bi-briefcase pe-3"></i>Framleiðendur
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/servicelocations/">
                <i className="bi bi-geo-alt-fill pe-3"></i>Móttökustaðir
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className="col pt-3 ps-4 min-vh-100"
          style={{ backgroundColor: "#f5f7fa" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
