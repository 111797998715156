import { useAPIQuery } from "../../hooks/api-hooks";
import { Link, useSearchParams } from "react-router-dom";
import { formatDateTime } from "../../utils/datetime";
import Status from "../../components/Status";
import { Button } from "react-bootstrap";
import EntryId from "../../components/EntryId";

export default function IntakeList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") ?? 0);
  const query = useAPIQuery("intakes/", {
    queryKey: ["intakes", offset],
    params: { offset },
  });

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  return (
    <div>
      <table className="table mt-4">
        <thead>
          <tr>
            <th>Númer</th>
            <th>Viðskiptavinur</th>
            <th>Tengiliður</th>
            <th>Móttökustaður</th>
            <th>Stofnuð</th>
            <th>Staða</th>
          </tr>
        </thead>
        <tbody>
          {query.isLoading && (
            <tr>
              <td>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Hleður...</span>
                </div>
              </td>
            </tr>
          )}
          {query.isSuccess &&
            query.data.map((intake) => (
              <tr key={intake.id}>
                <td>
                  <Link to={`/intakes/${intake.id}`}>
                    <EntryId entryId={intake.id} />
                  </Link>
                </td>
                <td>
                  <Link to={`/customers/${intake.customer.kt}`}>
                    {intake.customer.name}
                  </Link>
                </td>
                <td>{intake.contact?.name}</td>
                <td>{intake.service_location.name}</td>
                <td>{formatDateTime(intake.created_at)}</td>
                <td>
                  <Status status={intake.status} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex">
        <div>
          <Button
            disabled={offset === 0}
            onClick={() => setSearchParams({ offset: offset - 20 })}
          >
            <i className="bi bi-chevron-left"></i> Fyrri
          </Button>
        </div>
        <div className="ms-auto">
          <Button onClick={() => setSearchParams({ offset: offset + 20 })}>
            Næsta <i className="bi bi-chevron-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
}
