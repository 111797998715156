import { useQueryClient } from "react-query";
import { useAPIMutation } from "../../hooks/api-hooks";
import { Status } from "../../utils/status";
import { useState, useRef } from "react";

export default function TicketWorkDescription({ ticket }) {
  const queryClient = useQueryClient();
  const mutation = useAPIMutation(`tickets/${ticket.id}`, {
    method: "patch",
    onSuccess: (data) => {
      queryClient.setQueryData(["ticket", ticket.id], data);
      setEditing(false);
    },
  });

  const [editing, setEditing] = useState(false);

  const inputRef = useRef();

  const editable = [Status.IN_PROGRESS, Status.WAITING].includes(ticket.status);

  const updateWorkDescription = () => {
    mutation.mutate({ work_description: inputRef.current.value });
  };

  if (editable && editing) {
    return (
      <div>
        <textarea
          className="form-control mb-3"
          defaultValue={ticket.work_description}
          ref={inputRef}
          disabled={mutation.isLoading}
          style={{ minHeight: "12rem" }}
        />
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setEditing(false)}
            disabled={mutation.isLoading}
          >
            Hætta við
          </button>
          <button
            className="btn btn-primary ms-auto"
            onClick={updateWorkDescription}
            disabled={mutation.isLoading}
          >
            Vista
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ whiteSpace: "pre-wrap" }}>{ticket.work_description}</div>
        {editable && (
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => setEditing(true)}
            >
              Breyta
            </button>
          </div>
        )}
      </div>
    );
  }
}
