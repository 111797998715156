import { useAPIQuery } from "../../hooks/api-hooks";
import TicketCard from "./TicketCard";

export default function TicketColumn({
  serviceLocationId,
  status,
  title,
  interactions,
}) {
  const query = useAPIQuery("tickets/", {
    params: {
      service_location_id: serviceLocationId,
      status: status,
      limit: false,
      interactions,
    },
    queryKey: ["tickets", status, serviceLocationId],
  });
  return (
    <>
      <h5>
        {title}
        {query.isSuccess && ` (${query.data.length})`}
      </h5>
      {query.isLoading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Hleður...</span>
        </div>
      )}
      {query.isError && (
        <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
      )}
      {query.isSuccess &&
        query.data.map((ticket) => (
          <TicketCard
            ticket={ticket}
            key={ticket.id}
            interactions={interactions}
          />
        ))}
    </>
  );
}
