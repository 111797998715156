import { NavLink, Outlet } from "react-router-dom";

export default function Tickets() {
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-clipboard pe-3"></i> Verk
      </h1>
      <ul className="nav nav-pills mb-3">
        <li className="nav-item">
          <NavLink className="nav-link" to="/tickets" end>
            Verkröðun
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/tickets/all" end>
            Öll verk
          </NavLink>
        </li>
      </ul>
      <Outlet />
    </>
  );
}
