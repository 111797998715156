import { Outlet } from "react-router-dom";

export default function Customers() {
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-building pe-3"></i>Viðskiptavinir
      </h1>
      <Outlet />
    </>
  );
}
