import ProductLineForm from "./ProductLineForm";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import { Status } from "../../utils/status";

export default function TicketProductLines({ ticket }) {
  const editable = [Status.IN_PROGRESS, Status.WAITING].includes(ticket.status);

  const queryClient = useQueryClient();

  const createMutation = useAPIMutation("productlines/", {
    onSuccess: (data) =>
      queryClient.setQueryData(["ticket", ticket.id], (oldTicket) => ({
        ...oldTicket,
        productlines: [...oldTicket.productlines, data],
      })),
  });

  const deleteMutation = useAPIMutation("productlines/", {
    method: "delete",
    extraUrl: "productLineId",
  });

  const handleDeleteClick = (productLineId) => {
    deleteMutation.mutate(
      { productLineId },
      {
        onSuccess: () => {
          queryClient.setQueryData(["ticket", ticket.id], (oldTicket) => ({
            ...oldTicket,
            productlines: oldTicket.productlines.filter(
              (line) => line.id !== productLineId
            ),
          }));
        },
      }
    );
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Númer</th>
            <th>Heiti</th>
            <th>Magn</th>
            {editable && <th />}
          </tr>
        </thead>
        <tbody>
          {ticket.productlines.map((productLine) => (
            <tr key={productLine.id}>
              <td>{productLine.product_id}</td>
              <td>{productLine.product_name}</td>
              <td>{productLine.amount}</td>
              {editable && (
                <td>
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={(e) =>
                      handleDeleteClick(
                        parseInt(e.currentTarget.dataset.productLineId)
                      )
                    }
                    data-product-line-id={productLine.id}
                    disabled={deleteMutation.isLoading}
                  >
                    <i className="bi bi-trash3" />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {editable && (
        <ProductLineForm
          onSubmit={(productLine, reset) =>
            createMutation.mutate(
              { ...productLine, ticket_id: ticket.id },
              { onSuccess: () => reset() }
            )
          }
          disabled={createMutation.isLoading}
        />
      )}
    </>
  );
}
