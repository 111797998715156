import { useQueryClient } from "react-query";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useState, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import { Status } from "../../utils/status";

export default function TicketHiddenNote({
  ticketId,
  hiddenNote,
  ticketStatus,
}) {
  const inputRef = useRef();
  const [editing, setEditing] = useState(false);
  const editable = ticketStatus !== Status.DELIVERED;
  const queryClient = useQueryClient();
  const mutation = useAPIMutation(`tickets/${ticketId}`, {
    method: "patch",
    onSuccess: (data) => {
      queryClient.setQueryData(["ticket", ticketId], data);
      setEditing(false);
    },
  });

  const handleClick = () => {
    mutation.mutate({ hidden_note: inputRef.current.value });
  };

  return (
    <>
      <h3>Upplýsingar fyrir starfsmenn</h3>
      <hr />
      {editing ? (
        <>
          <textarea
            className="form-control mb-3"
            defaultValue={hiddenNote}
            ref={inputRef}
            disabled={mutation.isLoading}
            style={{ minHeight: "12rem" }}
          />
          <div className="d-flex">
            <div>
              <Button onClick={() => setEditing(false)} variant="danger">
                Hætta við
              </Button>
            </div>
            <div className="ms-auto">
              <Button onClick={handleClick}>Vista</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-5" style={{ whiteSpace: "pre-wrap" }}>
            {hiddenNote}
          </div>
          {editable && (
            <div className="d-flex justify-content-end">
              <div>
                <Button onClick={() => setEditing(true)}>Breyta</Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
