export const Status = {
  NEW: "new",
  IN_PROGRESS: "in_progress",
  WAITING: "waiting",
  WORK_COMPLETE: "work_complete",
  DELIVERED: "delivered",
};

export const Translations = {
  NEW: "Nýtt",
  IN_PROGRESS: "Í vinnslu",
  WAITING: "Bíður",
  WORK_COMPLETE: "Vinnu lokið",
  DELIVERED: "Afhent",
};
