export default function InteractionIcons({ interactions }) {
  if (!Array.isArray(interactions) || interactions.length === 0) return;

  return (
    <span className="me-3">
      {interactions.slice(-3).map((interaction) => (
        <i
          className={
            "bi ms-2 " +
            (interaction.success
              ? (interaction.type === "call"
                  ? "bi-check-circle-fill"
                  : "bi-chat-left-text-fill") + " text-success"
              : "bi-x-circle-fill text-danger")
          }
          title={interaction.created_at.slice(0, 10)}
          key={interaction.id}
        ></i>
      ))}
    </span>
  );
}
