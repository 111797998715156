export default function Status({ status }) {
  const translated = {
    new: { text: "Nýtt", className: "text-bg-primary" },
    in_progress: { text: "Í vinnslu", className: "text-bg-secondary" },
    waiting: { text: "Bíður", className: "text-bg-warning" },
    work_complete: { text: "Vinnu lokið", className: "text-bg-success" },
    delivered: { text: "Afhent", className: "text-bg-success" },
  };

  const entry = translated[status];

  return <span className={"badge " + entry.className}>{entry.text}</span>;
}
