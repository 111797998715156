import { Link } from "react-router-dom";
import EntryId from "../../components/EntryId";

export default function InvoiceList({ invoices }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Reikningur</th>
          <th>Verk</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr key={invoices.id}>
            <td>{invoice.id}</td>
            <td>
              <Link to={`/tickets/${invoice.ticket_id}`}>
                <EntryId entryId={invoice.ticket_id} />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
