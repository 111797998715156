import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useAPIQuery } from "../hooks/api-hooks";
import { useRef } from "react";

export default function EntitySelectModal({ show, onClose, onSelect }) {
  const [phrase, setPhrase] = useState("");
  const input = useRef(null);

  const handleClick = () => setPhrase(input.current.value);
  const handleExited = () => setPhrase("");

  const { data, isLoading } = useAPIQuery("customers/", {
    params: { q: phrase },
    queryKey: ["customers", phrase],
    enabled: phrase !== "",
  });

  const handleSelect = (e) => {
    onSelect(e.currentTarget.dataset.kt);
  };

  const handleKeyDown = (e) => {
    if (Object.hasOwn(e, "keyCode") && e.keyCode === 13) {
      e.stopPropagation();
      setPhrase(input.current.value);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      animation={false}
      onExited={handleExited}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        Leita að viðskiptavini eftir nafni
      </Modal.Header>
      <Modal.Body>
        <div className="input-group mb-3">
          <input
            type="search"
            className="form-control"
            ref={input}
            disabled={isLoading}
            onKeyDown={handleKeyDown}
            placeholder="Nafn viðskiptavinar"
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleClick}
            disabled={isLoading}
          >
            Leita
          </button>
        </div>
        {data &&
          data.map((entity) => (
            <div
              className="mb-2"
              key={entity.kt}
              style={{ cursor: "pointer" }}
              data-kt={entity.kt}
              onClick={handleSelect}
            >
              <div>
                <strong>{entity.name}</strong>
              </div>
              <div style={{ fontSize: "14px" }}>
                {entity.kt.substring(0, 6)}-{entity.kt.substring(6)}
              </div>
              <div>{entity.address}</div>
              <div>{entity.post_code}</div>
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );
}
