import { Outlet } from "react-router-dom";
import EquipmentNavBar from "./EquipmentNavBar";

export default function Equipment() {
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-wrench-adjustable-circle pe-3"></i> Tæki
      </h1>
      <EquipmentNavBar />
      <Outlet />
    </>
  );
}
