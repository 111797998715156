import { useAPIQuery } from "../../hooks/api-hooks";
import { useSearchParams } from "react-router-dom";
import TicketList from "../../components/TicketList";
import EquipmentList from "../../components/EquipmentList";
import CustomerList from "../../components/CustomerList";
import ContactList from "../../components/ContactList";
import InvoiceList from "./InvoiceList";

export default function Search() {
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const query = useAPIQuery("search/", {
    params: { q },
    queryKey: ["search", q],
    refetchOnWindowFocus: false,
    retry: false,
  });
  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }
  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }
  return (
    <>
      <h1>Leitarniðurstöður</h1>
      <h3>Verk</h3>
      <TicketList tickets={query.data.tickets} owner />
      <h3>Tæki</h3>
      <EquipmentList equipmentList={query.data.equipment} />
      <h3>Viðskiptavinir</h3>
      <CustomerList customers={query.data.customers} />
      <h3>Tengiliðir</h3>
      <ContactList contacts={query.data.contacts} />
      <h3>Reikningar</h3>
      <InvoiceList invoices={query.data.invoices} />
    </>
  );
}
