import { useState } from "react";
import EntitySelectModal from "./EntitySelectModal";
import SimpleCustomerDisplay from "./SimpleCustomerDisplay";
import DirectKTInput from "./DirectKTInput";

export function KTInput({ value, onChange, disabled }) {
  const [modalShowing, setModalShowing] = useState(false);

  const modalClose = () => setModalShowing(false);

  const handleSelect = (kt) => {
    onChange(kt);
    setModalShowing(false);
  };

  if (value) {
    return (
      <div
        className="d-flex mb-3"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ced4da",
          padding: "0.625rem 0.75rem",
          borderRadius: "0.375rem",
        }}
      >
        <SimpleCustomerDisplay kt={value} className="flex-grow-1" />
        <button
          className="btn fs-2 m-0 p-0"
          type="button"
          onClick={() => onChange("")}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="d-flex gap-2 mb-3">
        <DirectKTInput onVerified={handleSelect} disabled={disabled} />
        <button
          className="btn btn-primary ms-auto"
          onClick={() => setModalShowing(true)}
          type="button"
          disabled={disabled}
        >
          Leita eftir nafni
        </button>
      </div>
      <EntitySelectModal
        show={modalShowing}
        onClose={modalClose}
        onSelect={handleSelect}
      />
    </>
  );
}
