import { useAPIQuery } from "../../hooks/api-hooks";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function EquipmentList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") ?? 0);
  const query = useAPIQuery("equipment/", {
    queryKey: ["equipments", offset],
    params: { offset },
  });

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  return (
    <div>
      <table className="table mt-4">
        <thead>
          <tr>
            <th>Framleiðandi</th>
            <th>Módel</th>
            <th>Eigandi</th>
            <th>Vörunúmer</th>
            <th>Skráningarnúmer</th>
            <th>Raðnúmer</th>
            <th>Kaupdagsetning</th>
          </tr>
        </thead>
        <tbody>
          {query.isSuccess &&
            query.data.map((equipment) => (
              <tr key={equipment.id}>
                <td>{equipment.manufacturer.name}</td>
                <td>
                  <Link to={`/equipment/${equipment.id}`}>
                    {equipment.identifier}
                  </Link>
                </td>
                <td>
                  <Link to={`/customers/${equipment.customer.kt}`}>
                    {equipment.customer.name}
                  </Link>
                </td>
                <td>{equipment.product_id}</td>
                <td>{equipment.registration_no}</td>
                <td>{equipment.serial_no}</td>
                <td>{equipment.buy_date}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex">
        <div>
          <Button
            disabled={offset === 0}
            onClick={() => setSearchParams({ offset: offset - 20 })}
          >
            <i className="bi bi-chevron-left"></i> Fyrri
          </Button>
        </div>
        <div className="ms-auto">
          <Button onClick={() => setSearchParams({ offset: offset + 20 })}>
            Næsta <i className="bi bi-chevron-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
}
