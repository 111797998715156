import { useForm } from "react-hook-form";

export default function ProductLineForm({ onSubmit, disabled }) {
  const form = useForm();
  const errors = form.formState.errors;

  const submit = (data) => {
    onSubmit(data, form.reset);
  };

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <h4>Bæta við vörulínum</h4>
      <div className="d-flex">
        <div className="form-floating flex-fill">
          <input
            type="text"
            className="form-control"
            placeholder="Vörunúmer"
            {...form.register("product_id", { required: "Vörunúmer vantar" })}
            disabled={disabled}
          />
          <label>Vörunúmer</label>
          {errors.product_id && (
            <div className="alert alert-warning">
              {errors.product_id?.message}
            </div>
          )}
        </div>
        <div className="form-floating flex-fill ms-3">
          <input
            type="text"
            className="form-control"
            placeholder="Vöruheiti"
            {...form.register("product_name")}
            disabled={disabled}
          />
          <label>Vöruheiti</label>
        </div>
        <div className="form-floating flex-fill ms-3">
          <input
            type="text"
            className="form-control"
            placeholder="Magn"
            {...form.register("amount", {
              required: "Magn vantar",
              pattern: {
                value: /^\d+(,\d+)?$/,
                message: "Magn þarf að vera tala",
              },
            })}
            disabled={disabled}
          />
          <label>Magn</label>
          {errors.amount && (
            <div className="alert alert-warning">{errors.amount.message}</div>
          )}
        </div>
        <div className="d-flex align-items-center ms-3">
          <button className="btn btn-primary" disabled={disabled}>
            Vista
          </button>
        </div>
      </div>
    </form>
  );
}
