import { useMsal } from "@azure/msal-react";

export default function RoleRequired({ requiredRole, children }) {
  const { accounts } = useMsal();
  const hasRoles = accounts[0]?.idTokenClaims.roles ?? [];
  let inRole = false;
  if (Array.isArray(requiredRole)) {
    requiredRole.forEach((role) => {
      if (hasRoles.includes(role)) {
        inRole = true;
      }
    });
  } else {
    if (hasRoles.includes(requiredRole)) {
      inRole = true;
    }
  }
  if (inRole === true) {
    return children;
  }
}
