import { useAPIQuery } from "../hooks/api-hooks";

export default function ContactSelect({
  customer_kt,
  onClickCreate,
  onClickUpdate,
  value,
  onChange,
  disabled,
}) {
  const { data, isLoading } = useAPIQuery("contacts/", {
    params: { kt: customer_kt },
    queryKey: ["contacts", customer_kt],
    enabled: !!customer_kt,
  });

  const isDisabled = isLoading || !customer_kt || disabled;

  return (
    <div className="mb-3 d-flex gap-2">
      <div className="form-floating flex-grow-1">
        <select
          className="form-select"
          disabled={isDisabled}
          onChange={onChange}
          value={value}
        >
          <option value="">Veldu tengilið</option>
          {data &&
            data.map((contact) => (
              <option key={contact.id} value={contact.id}>
                {contact.name} {contact.kt && "- " + contact.kt}{" "}
                {contact.mobile && "- " + contact.mobile}{" "}
                {contact.email && "- " + contact.email}
              </option>
            ))}
        </select>
        <label>Tengiliður fyrir viðskiptavin</label>
      </div>
      <div className="btn-group">
        <button
          className="btn btn-primary"
          type="button"
          disabled={isDisabled}
          onClick={onClickCreate}
        >
          <i className="bi bi-plus"></i>
        </button>
        <button
          className="btn btn-primary"
          type="button"
          disabled={isDisabled}
          onClick={onClickUpdate}
        >
          <i className="bi bi-pencil"></i>
        </button>
      </div>
    </div>
  );
}
