import { useAPIQuery } from "../../hooks/api-hooks";
import { Link, useSearchParams } from "react-router-dom";
import Status from "../../components/Status";
import Priority from "../../components/Priority";
import { formatDateTime } from "../../utils/datetime";
import { Button } from "react-bootstrap";
import EntryId from "../../components/EntryId";

export default function TicketList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") ?? 0);
  const query = useAPIQuery("tickets/", {
    queryKey: ["tickets", offset],
    params: { offset },
  });

  const tickets = query.data;

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Númer</th>
            <th>Forgangur</th>
            <th>Tæki</th>
            <th>Viðskiptavinur</th>
            <th>Tengiliður</th>
            <th>Stofnað</th>
            <th>Staða</th>
          </tr>
        </thead>
        <tbody>
          {query.isLoading && (
            <tr>
              <td>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Hleður...</span>
                </div>
              </td>
            </tr>
          )}
          {query.isError && (
            <tr>
              <td>
                <div className="alert alert-warning">
                  {query.data.error.message}
                </div>
              </td>
            </tr>
          )}
          {query.isSuccess &&
            tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>
                  <Link to={`/tickets/${ticket.id}`}>
                    <EntryId entryId={ticket.id} />
                  </Link>
                </td>
                <td>
                  <Priority priority={ticket.priority} />
                </td>
                <td>
                  <Link to={`/equipment/${ticket.equipment.id}`}>
                    {ticket.equipment.manufacturer.name}{" "}
                    {ticket.equipment.identifier}
                  </Link>
                </td>
                <td>{ticket.customer.name}</td>
                <td>{ticket.contact?.name}</td>
                <td>{formatDateTime(ticket.created_at)}</td>
                <td>
                  <Status status={ticket.status} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex">
        <div>
          <Button
            disabled={offset === 0}
            onClick={() => setSearchParams({ offset: offset - 20 })}
          >
            <i className="bi bi-chevron-left"></i> Fyrri
          </Button>
        </div>
        <div className="ms-auto">
          <Button onClick={() => setSearchParams({ offset: offset + 20 })}>
            Næsta <i className="bi bi-chevron-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
}
