import { Fragment } from "react";
import { useFormContext, Controller } from "react-hook-form";
import EquipmentSelect from "../equipment/EquipmentSelect";
import { priorities } from "../../utils/priority";

export default function TicketCreate({
  customer_kt,
  equipmentClickCreate,
  equipmentClickUpdate,
  index,
  disabled,
}) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const equipmentId = watch(`tickets.${index}.equipment_id`);

  const isDisabled = !equipmentId || disabled;

  return (
    <Fragment>
      <hr />
      <h3 className="flex-grow-1">Tæki {index + 1}</h3>
      <Controller
        control={control}
        name={`tickets.${index}.equipment_id`}
        render={({ field: { onChange, value } }) => (
          <EquipmentSelect
            customer_kt={customer_kt}
            onClickCreate={() =>
              equipmentClickCreate(`tickets.${index}.equipment_id`)
            }
            onClickUpdate={() => equipmentClickUpdate(equipmentId)}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        )}
        rules={{ required: true, minLength: 1 }}
      />
      {errors?.tickets?.[index]?.equipment_id && (
        <div className="alert alert-warning">Tæki vantar</div>
      )}

      <div className="mb-3 form-floating">
        <textarea
          className="form-control"
          {...register(`tickets.${index}.service_description`, {
            required: "Lýsingu vantar",
            minLength: {
              value: 5,
              message: "Lýsing þarf að vera minnst 5 bókstafir",
            },
          })}
          style={{ height: "5rem" }}
          disabled={isDisabled}
        />
        <label>Lýsing á þjónustu</label>
        {errors?.tickets?.[index]?.service_description && (
          <div className="alert alert-warning">
            {errors.tickets[index].service_description.message}
          </div>
        )}
      </div>

      <div className="mb-3 form-floating">
        <select
          className="form-select"
          {...register(`tickets.${index}.priority`, {
            required: true,
            valueAsNumber: true,
            value: 2,
          })}
          disabled={isDisabled}
        >
          {priorities.map((priority, index) => (
            <option key={index} value={index}>
              {priority}
            </option>
          ))}
        </select>
        <label>Forgangur</label>
      </div>

      <div className="mb-3 form-floating">
        <textarea
          className="form-control"
          {...register(`tickets.${index}.hidden_note`, {})}
          style={{ height: "5rem" }}
          disabled={isDisabled}
        />
        <label>Upplýsingar fyrir starfsmenn</label>
      </div>
    </Fragment>
  );
}
