import { useAPIQuery } from "../hooks/api-hooks";
import { useState } from "react";

export default function DirectKTInput({ onVerified, disabled = false }) {
  const [error, setError] = useState("");
  const [candidate, setCandidate] = useState("");

  const handleSuccess = (inComingData) => {
    if (inComingData !== null) {
      onVerified(candidate);
    } else {
      setError("Kennitala fannst ekki");
    }
  };

  const { isLoading } = useAPIQuery(`customers/${candidate}`, {
    queryKey: ["customer", candidate],
    enabled: candidate !== "",
    onSuccess: handleSuccess,
    onError: () =>
      setError("Viðskiptavinur fannst ekki eða villa í uppflettingu"),
    retry: false,
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setCandidate(e.currentTarget.value.replace("-", "").trim());
    }
  };

  return (
    <div className="form-floating flex-grow-1">
      <input
        type="text"
        className="form-control"
        placeholder="Kennitala viðskiptavinar"
        onKeyDown={handleKeyDown}
        disabled={isLoading || disabled}
        onChange={() => setError("")}
        onBlur={(e) => setCandidate(e.target.value.trim())}
      />
      <label>Kennitala viðskiptavinar</label>
      {error && <div className="alert alert-warning">{error}</div>}
    </div>
  );
}
