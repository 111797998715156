import { Link } from "react-router-dom";
import Status from "./Status";
import Priority from "./Priority";
import { formatDateTime } from "../utils/datetime";
import EntryId from "../components/EntryId";

export default function TicketList({
  tickets,
  owner = false,
  created_at = true,
}) {
  return (
    <table className="table bg-white">
      <thead>
        <tr>
          <th>Númer</th>
          <th>Tæki</th>
          <th>Staða</th>
          <th>Forgangur</th>
          {owner && <th>Eigandi</th>}
          {created_at && <th>Stofnað</th>}
        </tr>
      </thead>
      <tbody>
        {tickets &&
          tickets.map((ticket) => (
            <tr key={ticket.id}>
              <td>
                <Link to={`/tickets/${ticket.id}`}>
                  <EntryId entryId={ticket.id} />
                </Link>
              </td>
              <td>
                <Link to={`/equipment/${ticket.equipment.id}`}>
                  {ticket.equipment.manufacturer.name}{" "}
                  {ticket.equipment.identifier}
                </Link>
              </td>
              <td>
                <Status status={ticket.status} />
              </td>
              <td>
                <Priority priority={ticket.priority} />
              </td>
              {owner && (
                <td>
                  <Link to={`/customers/${ticket.customer.kt}`}>
                    {ticket.customer.name}
                  </Link>
                </td>
              )}
              {created_at && (
                <td>{formatDateTime(ticket.created_at, false)}</td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
}
