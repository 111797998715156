import { useAPIQuery } from "../../hooks/api-hooks";

export default function EquipmentSelect({
  customer_kt,
  onClickCreate,
  onClickUpdate,
  value,
  onChange,
  disabled,
}) {
  const { data, isLoading } = useAPIQuery(`equipment/`, {
    params: { kt: customer_kt, limit: false },
    queryKey: ["equipment", customer_kt],
    enabled: !!customer_kt,
  });

  const isDisabled = isLoading || !customer_kt || disabled;

  return (
    <div className="mb-3 d-flex gap-2">
      <div className="form-floating flex-grow-1">
        <select
          className="form-select"
          disabled={isDisabled}
          onChange={onChange}
          value={value}
        >
          <option value="">Veldu tæki</option>
          {data &&
            data.map((equipment) => (
              <option key={equipment.id} value={equipment.id}>
                {equipment.manufacturer.name + " " + equipment.identifier}{" "}
                {(equipment.registration_no || equipment.serial_no) &&
                  "(" +
                    (equipment.registration_no || equipment.serial_no || null) +
                    ")"}
              </option>
            ))}
        </select>
        <label>Tæki</label>
      </div>
      <div className="btn-group">
        <button
          className="btn btn-primary"
          type="button"
          disabled={isDisabled}
          onClick={onClickCreate}
        >
          <i className="bi bi-plus"></i>
        </button>
        <button
          className="btn btn-primary"
          type="button"
          disabled={isDisabled}
          onClick={onClickUpdate}
        >
          <i className="bi bi-pencil"></i>
        </button>
      </div>
    </div>
  );
}
