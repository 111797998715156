import { useForm, Controller, FormProvider } from "react-hook-form";
import { useAPIMutation } from "../../hooks/api-hooks";
import { KTInput } from "../../components/KTInput";
import ContactSelect from "../../components/ContactSelect";
import ContactCreateModal from "./ContactCreateModal";
import ContactUpdateModal from "./ContactUpdateModal";
import { useEffect, useState } from "react";
import EquipmentCreateModal from "./EquipmentCreateModall";
import ServiceLocationSelect from "../../components/ServiceLocationSelect";
import TicketCreate from "./TicketCreate";
import { useNavigate } from "react-router-dom";
import EquipmentUpdateModal from "./EquipmentUpdateModal";
import { useLocalStorage } from "../../hooks/uselocalstorage";

export default function IntakeCreate() {
  const [serviceLocationId, setServiceLocationId] = useLocalStorage(
    "serviceLocation",
    null
  );
  const [modalShowing, setModalShowing] = useState("");
  const [tickets, setTickets] = useState([crypto.randomUUID()]);
  const [equipmentId, setEquipmentId] = useState("");
  const formProps = useForm({
    defaultValues: {
      service_location_id: serviceLocationId,
    },
  });

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
    unregister,
    setValue,
  } = formProps;

  const [customer_kt, contactId] = watch(["customer_kt", "contact_id"]);

  useEffect(() => {
    if (!customer_kt) {
      resetField("contact_id");
      tickets.forEach((_element, elementIndex) =>
        resetField(`tickets.${elementIndex}.equipment_id`)
      );
    }
  }, [resetField, tickets, customer_kt]);

  const mutation = useAPIMutation("intakes/", {
    onSuccess: (data) => navigate(`/intakes/${data.id}`),
  });

  const onSubmit = (data) => {
    setServiceLocationId(data.service_location_id);
    mutation.mutate(data);
  };

  const remove = () => {
    setTickets(tickets.toSpliced(-1, 1));
    const elementIndex = tickets.length - 1;
    unregister([
      `tickets.${elementIndex}.equipment_id`,
      `tickets.${elementIndex}.service_description`,
      `tickets.${elementIndex}.priority`,
      `tickets.${elementIndex}.hidden_note`,
    ]);
  };

  return (
    <div className="row">
      <div className="col-4 col-xl-6">
        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="customer_kt"
              render={({ field: { onChange, value } }) => (
                <KTInput
                  value={value}
                  onChange={onChange}
                  disabled={mutation.isLoading}
                />
              )}
              rules={{ required: "Viðskiptavin vantar" }}
            />
            {errors.customer_kt && (
              <div className="alert alert-warning">
                {errors.customer_kt.message}
              </div>
            )}
            <Controller
              control={control}
              name="contact_id"
              render={({ field: { onChange, value } }) => (
                <ContactSelect
                  customer_kt={customer_kt}
                  onClickCreate={() => setModalShowing("contactCreate")}
                  onClickUpdate={() => setModalShowing("contactUpdate")}
                  onChange={onChange}
                  value={value}
                  disabled={mutation.isLoading}
                />
              )}
            />
            <Controller
              control={control}
              name="service_location_id"
              render={({ field: { onChange, value } }) => (
                <ServiceLocationSelect
                  onChange={onChange}
                  value={value}
                  disabled={mutation.isLoading}
                />
              )}
              rules={{ required: true, minLength: 1 }}
            />
            {errors.service_location_id && (
              <div className="alert alert-warning">Móttökustað vantar</div>
            )}
            {tickets.map((id, index) => (
              <TicketCreate
                key={id}
                index={index}
                customer_kt={customer_kt}
                equipmentClickCreate={(inputName) =>
                  setModalShowing({ inputName, modal: "equipmentCreate" })
                }
                equipmentClickUpdate={(equipmentId) =>
                  setEquipmentId(equipmentId)
                }
              />
            ))}
            <div className="d-flex">
              <div className="btn-group">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => setTickets([...tickets, crypto.randomUUID()])}
                  disabled={mutation.isLoading}
                >
                  Fjölga tækjum
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={remove}
                  disabled={tickets.length === 1 || mutation.isLoading}
                >
                  Fækka tækjum
                </button>
              </div>
              <button
                className="btn btn-primary ms-auto"
                disabled={mutation.isLoading}
              >
                Vista
              </button>
            </div>
          </form>
        </FormProvider>
        <ContactCreateModal
          customer_kt={customer_kt}
          show={modalShowing === "contactCreate"}
          onClose={() => setModalShowing("")}
          onCreate={(contactId) => setValue("contact_id", contactId)}
        />
        {modalShowing === "contactUpdate" && !!contactId && (
          <ContactUpdateModal
            customer_kt={customer_kt}
            contactId={contactId}
            show={true}
            onClose={() => setModalShowing("")}
          />
        )}
        <EquipmentCreateModal
          customer_kt={customer_kt}
          show={modalShowing?.modal === "equipmentCreate"}
          onClose={() => setModalShowing("")}
          onCreate={(newEquipmentId) =>
            setValue(modalShowing.inputName, newEquipmentId)
          }
        />
        {equipmentId && (
          <EquipmentUpdateModal
            customer_kt={customer_kt}
            equipmentId={equipmentId}
            show={true}
            onClose={() => setEquipmentId("")}
          />
        )}
      </div>
    </div>
  );
}
