import { Link } from "react-router-dom";

export default function ContactList({ contacts }) {
  return (
    <table className="table bg-white mt-4">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Sími</th>
          <th>Tölvupóstfang</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => (
          <tr key={contact.id}>
            <td>
              <Link to={`/contacts/${contact.id}`}>{contact.name}</Link>
            </td>
            <td>{contact.kt}</td>
            <td>{contact.mobile}</td>
            <td>{contact.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
