import { useAPIQuery, useAPIMutation } from "../../hooks/api-hooks";
import { useNavigate, useParams } from "react-router-dom";
import EquipmentCreateForm from "../../components/EquipmentCreateForm";

export default function EquipmentUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const query = useAPIQuery(`equipment/${id}`, { queryKey: ["equipment", id] });

  const mutation = useAPIMutation(`equipment/${id}`, {
    method: "put",
    onSuccess: (data) => {
      navigate(`/equipment/${data.id}`);
    },
  });

  const onSubmit = (data) => {
    const buy_date = data.buy_date;
    data.equipmentId = id;
    if (buy_date === "") {
      data = { ...data, buy_date: null };
    }
    mutation.mutate(data);
  };

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  return (
    <div className="row">
      <div className="col-4">
        <EquipmentCreateForm
          onSubmit={onSubmit}
          disabled={mutation.isLoading}
          equipment={query.data}
        />
      </div>
    </div>
  );
}
