import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import EntryId from "../../components/EntryId";

export default function CreateTextMessageDialog({ show, ticket, onClose }) {
  const ticketId = ticket.id;
  const firstName = ticket.contact.name.split(" ")[0];
  const defaultMessage = `Góðan dag, ${firstName}. Verki ${EntryId({
    entryId: ticket.id,
  })} (${ticket.equipment.manufacturer.name} ${
    ticket.equipment.identifier
  }) er lokið. Þú getur sótt tækið virka daga frá 8 til 17 á ${
    ticket.service_location.address
  }, ${
    ticket.service_location.post_code
  } ${ticket.service_location.post_code_entry.name.trim()}. Kveðja, Þór hf.`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { message: defaultMessage },
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useAPIMutation(
    "tickets/text/",
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["ticket", ticketId], (oldTicket) => ({
          ...oldTicket,
          interactions: [...oldTicket.interactions, data],
        }));
        close();
      },
    }
  );

  const onSubmit = (data) => {
    mutate({ ...data, ticket_id: ticketId });
  };

  const close = () => {
    reset();
    onClose();
  };

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>Senda smáskilaboð</Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{error.message}</div>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              placeholder="Skilaboð"
              style={{ height: "10em" }}
              {...register("message", {
                required: "Skilaboð mega ekki vera tóm.",
                minLength: {
                  value: 10,
                  message: "Skilaboð þurfa að vera minnst 10 stafir.",
                },
                maxLength: {
                  value: 200,
                  message: "Skilaboðin geta mest verið 200 stafir.",
                },
              })}
              disabled={isLoading}
            ></textarea>
            <label>Skilaboð</label>
          </div>
          {errors && errors.message && (
            <div className="alert alert-warning">{errors.message.message}</div>
          )}
          <div className="d-flex">
            <button className="btn btn-success ms-auto" disabled={isLoading}>
              Senda
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
