import { Link } from "react-router-dom";
import { formatDateTime } from "../../utils/datetime";
import Status from "../../components/Status";
import EntryId from "../../components/EntryId";

export default function IntakeList({ intakes }) {
  return (
    <table className="table bg-white mt-4">
      <thead>
        <tr>
          <th>Númer</th>
          <th>Tengiliður</th>
          <th>Móttökustaður</th>
          <th>Stofnuð</th>
          <th>Staða</th>
        </tr>
      </thead>
      <tbody>
        {intakes.map((intake) => (
          <tr key={intake.id}>
            <td>
              <Link to={`/intakes/${intake.id}`}>
                <EntryId entryId={intake.id} />
              </Link>
            </td>
            <td>{intake.contact?.name}</td>
            <td>{intake.service_location.name}</td>
            <td>{formatDateTime(intake.created_at)}</td>
            <td>
              <Status status={intake.status} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
