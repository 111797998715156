import { useParams } from "react-router-dom";
import { useAPIQuery } from "../../hooks/api-hooks";
import CustomerTile from "../../components/CustomerTile";
import TicketList from "../../components/TicketList";
import EquipmentList from "./EquipmentList";
import IntakeList from "./IntakeList";
import ContactList from "./ContactList";

export default function CustomerDetail() {
  const { kt } = useParams();
  const query = useAPIQuery(`customers/${kt}`, {
    queryKey: ["customers", kt],
    retry: false,
  });

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    if (query.error.response.status === 404) {
      return (
        <div className="alert alert-warning">Viðskiptavinur finnst ekki</div>
      );
    }
    return (
      <div className="alert alert-warning">
        Villa: {JSON.stringify(query.error)}
      </div>
    );
  }

  const customer = query.data;
  return (
    <div className="d-flex gap-2">
      <div>
        <CustomerTile customer={customer} title="Viðskiptavinur" />
      </div>
      <div className="flex-grow-1">
        <h3>Verk</h3>
        <TicketList tickets={customer.tickets} />
        <h3>Tæki</h3>
        <EquipmentList equipment={customer.equipments} />
        <h3>Móttökur</h3>
        <IntakeList intakes={customer.intakes} />
        <h3>Tengiliðir</h3>
        <ContactList contacts={customer.contacts} />
      </div>
    </div>
  );
}
