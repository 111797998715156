import { useState } from "react";
import CreateInteractionDialog from "./CreateInteractionDialog";

import { formatDateTime } from "../../utils/datetime";
import CreateTextMessageDialog from "./CreateTextMessageDialog";

export default function TicketInteractions({ ticket }) {
  const interactions = ticket.interactions;
  const ticketId = ticket.id;
  const [dialogShowing, setDialogShowing] = useState(false);
  const [textDialogShowing, setTextDialogShowing] = useState(false);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Dagsetning</th>
            <th>Tegund</th>
            <th>Niðurstaða</th>
            <th>Skilaboð</th>
            <th>Starfsmaður</th>
          </tr>
        </thead>
        <tbody>
          {interactions.map((interaction) => (
            <tr key={interaction.id}>
              <td>{formatDateTime(interaction.created_at)}</td>
              <td>{interaction.type === "call" ? "Símtal" : "Smáskilaboð"}</td>
              <td>{interaction.success ? "Svarað" : "Ekki svarað"}</td>
              <td>{interaction.message}</td>
              <td>{interaction.created_by.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => setTextDialogShowing(true)}
          disabled={!ticket?.contact?.mobile}
        >
          <i className="bi bi-chat-left-text"></i> Smáskilaboð
        </button>
        <button
          className="btn btn-primary ms-3"
          type="button"
          onClick={() => setDialogShowing(true)}
        >
          <i className="bi bi-telephone-plus-fill"></i> Bæta við
        </button>
      </div>
      {ticket.contact && (
        <CreateTextMessageDialog
          show={textDialogShowing}
          onClose={() => setTextDialogShowing(false)}
          ticket={ticket}
        />
      )}
      <CreateInteractionDialog
        show={dialogShowing}
        onClose={() => setDialogShowing(false)}
        ticketId={ticketId}
      />
    </>
  );
}
