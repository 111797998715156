import Modal from "react-bootstrap/Modal";
import { useAPIQuery, useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import EquipmentCreateForm from "../../components/EquipmentCreateForm";

export default function EquipmentUpdateModal({
  customer_kt,
  show,
  onClose,
  equipmentId,
}) {
  const client = useQueryClient();

  const query = useAPIQuery(`equipment/${equipmentId}`, {
    queryKey: ["equipment", equipmentId],
  });

  let equipment = {};
  if (query.isSuccess) {
    let {
      identifier,
      product_id,
      registration_no,
      serial_no,
      description,
      buy_date,
      manufacturer_id,
    } = query.data;
    equipment = {
      identifier,
      product_id,
      registration_no,
      serial_no,
      description,
      buy_date,
      manufacturer_id,
    };
  }

  const mutation = useAPIMutation(`equipment/${equipmentId}`, {
    method: "put",
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["equipment", customer_kt] });
      close();
    },
  });

  const close = () => {
    onClose();
  };

  const onSubmit = (data) => {
    mutation.mutate({
      ...data,
      customer_kt,
    });
  };

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>
        <h2>Uppfæra tæki</h2>
      </Modal.Header>
      <Modal.Body>
        {query.isSuccess ? (
          <EquipmentCreateForm
            customer_kt={customer_kt}
            disabled={mutation.isLoading || query.isLoading}
            onSubmit={onSubmit}
            error={mutation.error?.message}
            equipment={equipment}
          />
        ) : (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Hleður...</span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
