export function formatDateTime(dateString, time = true) {
  try {
    const dte = new Date(dateString);
    const year = dte.getFullYear();
    const month = ("0" + (dte.getMonth() + 1)).slice(-2);
    const day = ("0" + dte.getDate()).slice(-2);
    const hour = ("0" + dte.getHours()).slice(-2);
    const minutes = ("0" + dte.getMinutes()).slice(-2);
    let result = `${year}-${month}-${day}`;
    if (time) {
      result += ` ${hour}:${minutes}`;
    }
    return result;
  } catch {
    return "";
  }
}
