import Modal from "react-bootstrap/Modal";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import ContactForm from "../../components/forms/ContactForm";

export default function ContactCreateModal({
  customer_kt,
  show,
  onClose,
  onCreate,
}) {
  const client = useQueryClient();

  const { mutate, isLoading, error } = useAPIMutation("contacts/", {
    onSuccess: (data) => {
      client.setQueryData(["contacts", customer_kt], (oldData) => [
        ...oldData,
        data,
      ]);
      onCreate(data.id);
      close();
    },
  });

  function close() {
    onClose();
  }

  function onSubmit(data) {
    mutate({ ...data, customer_kt, email: data.email || null });
  }

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>
        <h2>Stofna tengilið</h2>
      </Modal.Header>
      <Modal.Body>
        <ContactForm disabled={isLoading} onSubmit={onSubmit} error={error} />
      </Modal.Body>
    </Modal>
  );
}
