import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../authConfig";
import { useMutation, useQuery } from "react-query";
import axios from "axios";

export function useAPIQuery(url, { params, ...RQParams }) {
  const { instance, accounts } = useMsal();

  const queryFn = async () => {
    const token = await instance.acquireTokenSilent({
      account: accounts[0],
      ...apiRequest,
    });
    const { data } = await axios({
      url,
      params,
      baseURL: process.env.REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${token.accessToken}` },
    });
    return data;
  };

  return useQuery({
    queryFn,
    ...RQParams,
  });
}

export function useAPIMutation(
  url,
  { params, method = "post", extraUrl, ...RQParams }
) {
  const { instance, accounts } = useMsal();

  const mutationFn = async (variables) => {
    let endpoint = url;
    if (!!extraUrl && extraUrl in variables) {
      endpoint += variables[extraUrl];
      delete variables[extraUrl];
    }
    const token = await instance.acquireTokenSilent({
      account: accounts[0],
      ...apiRequest,
    });
    const { data } = await axios({
      url: endpoint,
      method,
      params,
      data: variables,
      baseURL: process.env.REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${token.accessToken}` },
    });
    return data;
  };
  return useMutation({
    mutationFn,
    ...RQParams,
  });
}
