export const priorities = [
  "Minnstur",
  "Lítill",
  "Venjulegur",
  "Mikill",
  "Mestur",
];

export function getPriorityDisplay(idx) {
  return priorities[idx];
}
