import Detail from "./Detail";

export default function EquipmentTile({ equipment, title }) {
  return (
    <div
      className="bg-white border rounded p-3 mb-3"
      style={{ minWidth: "400px" }}
    >
      {title && <h3>{title}</h3>}
      <Detail title="Framleiðandi" value={equipment.manufacturer.name} />
      <Detail title="Módel" value={equipment.identifier} />
      <Detail title="Vörunúmer" value={equipment.product_id} />
      <Detail title="Skráningarnúmer" value={equipment.registration_no} />
      <Detail title="Raðnúmer" value={equipment.serial_no} />
      <Detail title="Kaupdagsetning" value={equipment.buy_date} />
      <Detail title="Nánari lýsing" value={equipment.description} />
      <Detail title="Stofnað af" value={equipment.created_by.name} />
    </div>
  );
}
