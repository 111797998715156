import { useAPIQuery } from "../hooks/api-hooks";

export default function SimpleCustomerDisplay({ kt, ...props }) {
  const { data, isLoading, isError, error } = useAPIQuery(`customers/${kt}`, {
    queryKey: ["customer", kt],
  });

  if (isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (isError) {
    return <p>Villa! {error}</p>;
  }

  return (
    <div {...props}>
      <div>
        <span className="fw-bold">{data.name} </span>
        {data.kt.substring(0, 6)}-{data.kt.substring(6)}
      </div>
      <div>
        {data.address} {data.post_code} {data?.post_code_entry?.name}
      </div>
    </div>
  );
}
