import { useState } from "react";
import ManufacturerModal from "./ManufacturerModal";
import RoleRequired from "../../components/auth/RoleRequired";
import { roles } from "../../authConfig";

export default function ManufacturerNavBar() {
  const [showing, setShowing] = useState(false);
  return (
    <div className="d-flex">
      <RoleRequired requiredRole={roles.Admin}>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => setShowing(true)}
        >
          <i className="bi bi-plus"></i> Bæta við
        </button>
        <ManufacturerModal show={showing} onClose={() => setShowing(false)} />
      </RoleRequired>
    </div>
  );
}
