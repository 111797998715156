import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

export default function ProfileDisplay() {
  const { instance, accounts } = useMsal();
  const account = accounts[0];

  return (
    <div className="ms-auto">
      {account?.name}
      <Button
        className="ms-2"
        variant="outline-primary"
        onClick={() => instance.logoutPopup({ postLogoutRedirectUri: "/" })}
        size="sm"
      >
        Útskrá
      </Button>
    </div>
  );
}
