import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { KT } from "../utils/regex";

export default function GlobalSearch() {
  const input = useRef();
  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      doSearch();
    }
  };

  const doSearch = () => {
    const term = input.current.value.trim();
    if (KT.test(term)) {
      navigate(`/customers/${term}`);
    } else if (/^V2\d{4}/.test(term)) {
      navigate(`/tickets/${term}`);
    } else if (/^M2\d{4}/.test(term)) {
      navigate(`/intakes/${term}`);
    } else {
      navigate(`/search?q=${term}`);
    }
  };

  return (
    <div className="ms-auto">
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </span>
        <input
          type="search"
          className="form-control"
          placeholder="Leit..."
          style={{ minWidth: "500px", maxWidth: "600px" }}
          onKeyDown={handleKeyDown}
          ref={input}
        />
      </div>
    </div>
  );
}
