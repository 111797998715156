import { Link } from "react-router-dom";

export default function CustomerList({ customers }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Heimilisfang</th>
          <th>Póstfang</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer) => (
          <tr key={customer.kt}>
            <td>
              <Link to={`/customers/${customer.kt}`}>{customer.name}</Link>
            </td>
            <td>{customer.kt}</td>
            <td>{customer.address}</td>
            <td>
              {customer.post_code &&
                `${customer.post_code} ${customer.post_code_entry.name}`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
