import Modal from "react-bootstrap/Modal";
import { useAPIQuery, useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";
import ContactForm from "../../components/forms/ContactForm";

export default function ContactUpdateModal({
  customer_kt,
  show,
  onClose,
  contactId,
}) {
  const client = useQueryClient();

  const query = useAPIQuery(`contacts/${contactId}`, {
    queryKey: ["contact", contactId],
  });

  let contact = {};
  if (query.isSuccess) {
    let { name, email, kt, mobile } = query.data;
    contact = { name, email, kt, mobile };
  }

  const mutation = useAPIMutation(`contacts/${contactId}`, {
    method: "put",
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["contacts", customer_kt] });
      close();
    },
  });

  const close = () => {
    onClose();
  };

  const onSubmit = (data) => {
    mutation.mutate({
      ...data,
      customer_kt,
      email: data.email || null,
    });
  };

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>
        <h2>Uppfæra tengilið</h2>
      </Modal.Header>
      <Modal.Body>
        {query.isSuccess && (
          <ContactForm
            disabled={mutation.isLoading || query.isLoading}
            onSubmit={onSubmit}
            error={mutation.error?.message}
            contact={contact}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
