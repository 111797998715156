import { useState } from "react";
import { roles } from "../../authConfig";
import ServiceLocationModal from "./ServiceLocationModal";
import RoleRequired from "../../components/auth/RoleRequired";

export default function ServiceLocationnavBar() {
  const [showing, setShow] = useState(false);
  return (
    <div className="d-flex">
      <RoleRequired requiredRole={roles.Admin}>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            setShow(true);
          }}
        >
          <i className="bi bi-plus"></i> Stofna móttökustað
        </button>
        <ServiceLocationModal
          show={showing}
          onClose={() => {
            setShow(false);
          }}
        />
      </RoleRequired>
    </div>
  );
}
