import Modal from "react-bootstrap/Modal";
import EquipmentCreateForm from "../../components/EquipmentCreateForm";
import { useQueryClient } from "react-query";
import { useAPIMutation } from "../../hooks/api-hooks";

export default function EquipmentCreateModal({
  customer_kt,
  show,
  onClose,
  onCreate,
}) {
  const queryClient = useQueryClient();
  const mutation = useAPIMutation("equipment/", {
    onSuccess: (data) => {
      queryClient.setQueryData(["equipment", customer_kt], (oldData) => [
        ...oldData,
        data,
      ]);
      onCreate(data.id);
      onClose();
    },
  });

  const onSubmit = (data) => {
    data.customer_kt = customer_kt;
    if (data.buy_date === "") {
      data.buy_date = null;
    }
    mutation.mutate(data);
  };

  return (
    <Modal show={show} onHide={onClose} animation={false} centered>
      <Modal.Header closeButton>
        <h2>Stofna tæki</h2>
      </Modal.Header>
      <Modal.Body>
        <EquipmentCreateForm
          customer_kt={customer_kt}
          onSubmit={onSubmit}
          disabled={mutation.isLoading}
        />
      </Modal.Body>
    </Modal>
  );
}
