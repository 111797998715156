import { Button, Card } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

export default function Login() {
  const { instance } = useMsal();
  const login = () => {
    instance.loginPopup(loginRequest);
  };

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 bg-dark-subtle">
      <Card>
        <Card.Body>
          <h1>Skírnir</h1>
          <div className="mb-2">
            Þú þarft að skrá þig inn til að geta notað Skírni.
          </div>
          <div className="text-center">
            <Button onClick={login}>Skrá inn</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
