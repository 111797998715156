import { useAPIQuery } from "../hooks/api-hooks";

export default function ServiceLocationSelect({ value, onChange, disabled }) {
  const { data, isLoading, isSuccess } = useAPIQuery("service_locations/", {
    queryKey: "servicelocations",
  });

  const isDisabled = isLoading || disabled;

  return (
    <div className="mb-3 form-floating">
      <select
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        className="form-select"
      >
        <option value="">Veldu móttökustað</option>
        {isSuccess &&
          data.map((location) => (
            <option value={location.id} key={location.id}>
              {location.name}
            </option>
          ))}
      </select>
      <label>Móttökustaður</label>
    </div>
  );
}
