import { useNavigate } from "react-router-dom";
import DirectKTInput from "../../components/DirectKTInput";

export default function CustomerSearch() {
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="col-12 col-xxl-4">
        <p>Leitað að viðskiptavini:</p>
        <DirectKTInput onVerified={(kt) => navigate(`/customers/${kt}`)} />
      </div>
    </div>
  );
}
