import { useState } from "react";
import CreateInvoiceDialog from "./CreateInvoiceDialog";
import { useQueryClient } from "react-query";
import { useAPIMutation } from "../../hooks/api-hooks";

export default function TicketInvoices({ invoices, ticketId }) {
  const [dialogShowing, setDialogShowing] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useAPIMutation("tickets/invoices/", {
    method: "delete",
    extraUrl: "invoiceId",
  });

  const handleDeleteClick = (invoiceId) => {
    mutation.mutate(
      { invoiceId },
      {
        onSuccess: () => {
          queryClient.setQueryData(["ticket", ticketId], (oldTicket) => ({
            ...oldTicket,
            invoices: oldTicket.invoices.filter(
              (invoice) => invoice.id !== invoiceId
            ),
          }));
        },
      }
    );
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Númer reiknings</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td>
                <div className="d-flex">
                  <div>{invoice.id}</div>
                  <div className="ms-auto">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={(e) =>
                        handleDeleteClick(e.currentTarget.dataset.invoiceId)
                      }
                      data-invoice-id={invoice.id}
                      disabled={mutation.isLoading}
                    >
                      <i className="bi bi-trash3" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex">
        <div className="ms-auto">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => setDialogShowing(true)}
          >
            Bæta við
          </button>
        </div>
      </div>
      <CreateInvoiceDialog
        show={dialogShowing}
        onClose={() => setDialogShowing(false)}
        ticketId={ticketId}
      />
    </>
  );
}
