import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";

export default function ServiceLocationModal({ show, onClose }) {
  const client = useQueryClient();

  const { mutate, isLoading, isError, error } = useAPIMutation(
    "service_locations/",
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: ["servicelocations"] });
        close();
      },
      defaultValues: { address: null, post_code: null },
    }
  );

  function close() {
    reset();
    onClose();
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  function onSubmit(data) {
    mutate(data);
  }

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>
        <h2>Stofna móttökustað</h2>
      </Modal.Header>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {isError && <div className="alert alert-danger">{error.message}</div>}
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Kóti"
              {...register("id", { required: true })}
              disabled={isLoading}
            />
            <label>Kóti</label>
            {errors.id && <p className="alert alert-warning">Kóta vantar.</p>}
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Heiti"
              {...register("name", { required: true })}
              disabled={isLoading}
            />
            <label>Heiti</label>
            {errors.name && <p className="alert alert-warning">Heiti vantar</p>}
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Heimilisfang"
              {...register("address")}
              disabled={isLoading}
            />
            <label>Heimilisfang</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Póstnúmer"
              inputMode="numeric"
              {...register("post_code", {
                pattern: /^[1-9]\d{2}$/,
                valueAsNumber: true,
              })}
              disabled={isLoading}
            />
            <label>Póstnúmer</label>
            {errors.post_code && (
              <p className="alert alert-warning">
                Póstnúmer þarf að vera þriggja stafa tala.
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Hleður...</span>
            </div>
          )}
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={close}
            disabled={isLoading}
          >
            Hætta við
          </button>
          <button
            className="btn btn-success"
            type="submit"
            disabled={!isValid || isLoading}
          >
            Vista
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
