import { useAPIQuery } from "../../hooks/api-hooks";
import ManufacturerNavBar from "./ManufacturerNavBar";

export default function Manufacturers() {
  const query = useAPIQuery("manufacturers/", { queryKey: "manufacturers" });

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-briefcase"></i> Framleiðendur
      </h1>
      <ManufacturerNavBar />
      <table className="table mt-4">
        <thead>
          <tr>
            <th>Heiti</th>
          </tr>
        </thead>
        <tbody>
          {query.isLoading && (
            <tr>
              <td>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Hleður...</span>
                </div>
              </td>
            </tr>
          )}
          {query.isError && (
            <tr>
              <td>
                <div className="alert alert-warning">{query.error.message}</div>
              </td>
            </tr>
          )}
          {query.isSuccess &&
            query.data.map((manufacturer) => (
              <tr key={manufacturer.id}>
                <td>{manufacturer.name}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
