import { useAPIQuery } from "../../hooks/api-hooks";
import { useParams } from "react-router-dom";
import Detail from "../../components/Detail";
import TicketList from "../../components/TicketList";
import { formatDateTime } from "../../utils/datetime";
import CustomerTile from "../../components/CustomerTile";
import Status from "../../components/Status";
import printJS from "print-js";
import EntryId from "../../components/EntryId";

export default function IntakeDetail() {
  const { id } = useParams();
  const query = useAPIQuery(`intakes/${id}`, {
    queryKey: ["intake", id],
    retry: false,
  });

  const intake = query.data;

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    if (query.error.response.status === 404) {
      return <div className="alert alert-warning">Móttaka finnst ekki</div>;
    }

    return (
      <div className="alert alert-warning">Villa: {query.error.message}</div>
    );
  }

  return (
    <div className="d-flex gap-2">
      <div className="">
        <CustomerTile customer={intake.customer} title="Eigandi tækis" />
        {intake?.contact && (
          <div className="bg-white border p-3 mb-3">
            <h3>Tengiliður</h3>
            <Detail title="Heiti" value={intake?.contact?.name} />
            <Detail title="Kennitala" value={intake?.contact?.kt} />
            <Detail
              title="Tölvupóstfang"
              value={
                <a href={"mailto:" + intake?.contact?.email}>
                  {intake?.contact?.email}
                </a>
              }
            />
            <Detail
              title="Sími"
              value={
                <a href={"tel:" + intake?.contact?.mobile}>
                  {intake?.contact?.mobile}
                </a>
              }
            />
          </div>
        )}
        <div className="bg-white border rounded p-3">
          <h3>Móttaka</h3>
          <Detail title="Númer" value={<EntryId entryId={intake?.id} />} />
          <Detail title="Stofnuð" value={formatDateTime(intake?.created_at)} />
          <Detail title="Stofnuð af" value={intake.created_by.name} />
          <Detail title="Móttökustaður" value={intake?.service_location.name} />
          <Detail title="Staða" value={<Status status={intake?.status} />} />
        </div>
      </div>
      <div className="flex-grow-1 p-3">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() =>
              printJS({
                printable: `${process.env.REACT_APP_API_URL}/sheets/intake_received/${intake.id}`,
              })
            }
          >
            <i className="bi bi-printer"></i> Prenta móttökukvittun
          </button>
        </div>
        <h3>Verk</h3>
        <TicketList tickets={intake?.tickets} created_at={false} />
      </div>
    </div>
  );
}
