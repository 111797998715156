import { useAPIQuery } from "../../hooks/api-hooks";
import { useParams } from "react-router-dom";
import EquipmentTile from "../../components/EquipmentTile";
import CustomerTile from "../../components/CustomerTile";
import TicketList from "../../components/TicketList";
import { Link } from "react-router-dom";

export default function EquipmentDetail({ equipmentId }) {
  const { id } = useParams();
  const query = useAPIQuery(`equipment/${id}`, {
    queryKey: ["equipment", id],
  });
  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }
  return (
    <div className="d-flex gap-2">
      <div>
        <EquipmentTile equipment={query.data} />
        <CustomerTile customer={query.data.customer} title="Eigandi" />
      </div>
      <div className="flex-grow-1 p-3">
        <div className="d-flex justify-content-end">
          <div>
            <Link className="btn btn-primary" to={`/equipment/edit/${id}`}>
              <i className="bi bi-pencil"></i> Breyta tæki
            </Link>
          </div>
        </div>

        <h3>Verk</h3>
        <TicketList tickets={query.data.tickets} owner={false} />
      </div>
    </div>
  );
}
