import { useAPIQuery } from "../../hooks/api-hooks";
import { Link, useParams } from "react-router-dom";
import EquipmentTile from "../../components/EquipmentTile";
import Detail from "../../components/Detail";
import { formatDateTime } from "../../utils/datetime";
import CustomerTile from "../../components/CustomerTile";
import ContactTile from "../../components/ContactTile";
import Status from "../../components/Status";
import Priority from "../../components/Priority";
import EntryId from "../../components/EntryId";
import TicketUpdate from "./TicketUpdate";
import TicketWorkDescription from "./TicketWorkDescription";
import TicketProductLines from "./TicketProductLines";
import printJs from "print-js";
import { Status as StatusConst } from "../../utils/status";
import TicketHiddenNote from "./TicketHiddenNote";
import TicketInteractions from "./TicketInteractions";
import TicketInvoices from "./TicketInvoices";

export default function TicketDetails() {
  const { id } = useParams();
  const query = useAPIQuery(`tickets/${id}`, {
    queryKey: ["ticket", id],
    retry: false,
  });

  const ticket = query.data;

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    if (query.error?.response?.status === 404) {
      return <div className="alert alert-warning">Verk finnst ekki</div>;
    }

    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  return (
    <div className="d-flex gap-2">
      <div>
        <div className="bg-white border rounded p-3 mb-3">
          <Detail title="Númer" value={<EntryId entryId={ticket.id} />} />
          <Detail title="Staða" value={<Status status={ticket.status} />} />
          <Detail
            title="Forgangur"
            value={<Priority priority={ticket.priority} />}
          />
          <Detail title="Stofnað" value={formatDateTime(ticket.created_at)} />
          <Detail title="Stofnað af" value={ticket.created_by.name} />
          <Detail
            title="Hafið"
            value={ticket.started_at ? formatDateTime(ticket.started_at) : ""}
          />
          <Detail
            title="Lokið"
            value={
              ticket.work_completed_at
                ? formatDateTime(ticket.work_completed_at)
                : ""
            }
          />
          <Detail
            title="Móttaka"
            value={
              <Link to={`/intakes/${ticket.intake_id}`}>
                <EntryId entryId={ticket.intake_id} />
              </Link>
            }
          />
          <Detail title="Móttökustaður" value={ticket.service_location.name} />
        </div>
        <EquipmentTile equipment={ticket.equipment} title="Tæki" />
        <CustomerTile customer={ticket.customer} title="Eigandi tækis" />
        {ticket.contact && (
          <ContactTile contact={ticket.contact} title="Tengiliður" />
        )}
      </div>
      <div className="flex-grow-1 p-3">
        <div className="d-flex mb-3">
          <TicketUpdate
            ticketId={ticket.id}
            status={ticket.status}
            priority={ticket.priority}
          />

          <div className="ms-auto">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() =>
                printJs({
                  printable: `${process.env.REACT_APP_API_URL}/sheets/ticket_complete/${ticket.id}`,
                })
              }
            >
              <i className="bi bi-printer"></i> Prenta verkseðil
            </button>
          </div>
        </div>
        <div className="d-flex mb-3"></div>
        <div className="row">
          <div className="col">
            <h3>Ósk um þjónustu</h3>
            <hr />
            <div className="mb-5" style={{ whiteSpace: "pre-wrap" }}>
              {ticket.service_description}
            </div>
          </div>
          <div className="col">
            <TicketHiddenNote
              ticketId={ticket.id}
              hiddenNote={ticket.hidden_note}
              ticketStatus={ticket.status}
            />
          </div>
        </div>
        {[StatusConst.WORK_COMPLETE, StatusConst.DELIVERED].includes(
          ticket.status
        ) ? (
          <>
            <h3>Samskipti</h3>
            <hr />
            <TicketInteractions ticket={ticket} />
          </>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col">
            <h3>Lýsing á hvað var gert</h3>
            <hr />
            <TicketWorkDescription ticket={ticket} />
          </div>
          <div className="col">
            <h3>Reikningar</h3>
            <hr />
            <TicketInvoices invoices={ticket.invoices} ticketId={ticket.id} />
          </div>
        </div>
        <h3>Vörulínur</h3>
        <hr />
        <TicketProductLines ticket={ticket} />
      </div>
    </div>
  );
}
