import { Link } from "react-router-dom";

export default function EquipmentList({ equipment }) {
  return (
    <table className="table bg-white">
      <thead>
        <tr>
          <th>Framleiðandi</th>
          <th>Módel</th>
          <th>Vörunúmer</th>
          <th>Skráningarnúmer</th>
          <th>Raðnúmer</th>
          <th>Kaupdagsetning</th>
        </tr>
      </thead>
      <tbody>
        {equipment.map((eq) => (
          <tr key={eq.id}>
            <td>{eq.manufacturer.name}</td>
            <td>
              <Link to={`/equipment/${eq.id}`}>{eq.identifier}</Link>
            </td>
            <td>{eq.product_id}</td>
            <td>{eq.registration_no}</td>
            <td>{eq.serial_no}</td>
            <td>{eq.buy_date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
