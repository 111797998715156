import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import Root from "./pages/root/Root";
import Home from "./pages/home/Home";
import Tickets from "./pages/tickets/Tickets";
import ServiceLocations from "./pages/servicelocations/ServiceLocations";
import Manufacturers from "./pages/manufacturers/Manufacturers";
import Equipment from "./pages/equipment/Equipment";
import EquipmentCreate from "./pages/equipment/EquipmentCreate";
import EquipmentList from "./pages/equipment/EquipmentList";
import EquipmentDetail from "./pages/equipment/EquipmentDetail";
import EquipmentUpdate from "./pages/equipment/EquipmentUpdate";
import Contacts from "./pages/contacts/Contacts";
import ContactList from "./pages/contacts/ContactList";
import ContactDetail from "./pages/contacts/ContactDetail";
import ContactUpdate from "./pages/contacts/ContactUpdate";
import Intakes from "./pages/intakes/Intakes";
import IntakeList from "./pages/intakes/IntakeList";
import IntakeDetail from "./pages/intakes/IntakeDetail";
import IntakeCreate from "./pages/intakes/IntakeCreate";
import TicketList from "./pages/tickets/TicketList";
import TicketDetails from "./pages/tickets/TicketDetail";
import TicketSorter from "./pages/tickets/TicketSorter";
import Customers from "./pages/customers/Customers";
import CustomerSearch from "./pages/customers/CustomerSearch";
import CustomerDetail from "./pages/customers/CustomerDetail";
import Search from "./pages/search/Search";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./pages/login/Login";

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "tickets",
        element: <Tickets />,
        children: [
          {
            path: "all",
            element: <TicketList />,
          },
          {
            path: ":id",
            element: <TicketDetails />,
          },
          {
            index: true,
            element: <TicketSorter />,
          },
        ],
      },
      {
        path: "manufacturers",
        element: <Manufacturers />,
      },
      {
        path: "servicelocations",
        element: <ServiceLocations />,
      },
      {
        path: "equipment",
        element: <Equipment />,
        children: [
          {
            index: true,
            element: <EquipmentList />,
          },
          {
            path: "create",
            element: <EquipmentCreate />,
          },
          {
            path: ":id",
            element: <EquipmentDetail />,
          },
          {
            path: "edit/:id",
            element: <EquipmentUpdate />,
          },
        ],
      },
      {
        path: "contacts",
        element: <Contacts />,
        children: [
          {
            index: true,
            element: <ContactList />,
          },
          {
            path: ":id",
            element: <ContactDetail />,
          },
          {
            path: "edit/:id",
            element: <ContactUpdate />,
          },
        ],
      },
      {
        path: "intakes",
        element: <Intakes />,
        children: [
          {
            index: true,
            element: <IntakeList />,
          },
          {
            path: "create",
            element: <IntakeCreate />,
          },
          {
            path: ":id",
            element: <IntakeDetail />,
          },
        ],
      },
      {
        path: "customers",
        element: <Customers />,
        children: [
          {
            element: <CustomerSearch />,
            index: true,
          },
          {
            path: ":kt",
            element: <CustomerDetail />,
          },
        ],
      },
      {
        path: "search",
        element: <Search />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>
);
