import { useAPIMutation } from "../../hooks/api-hooks";
import { useNavigate } from "react-router-dom";
import EquipmentCreateForm from "../../components/EquipmentCreateForm";

export default function EquipmentCreate() {
  const navigate = useNavigate();

  const mutation = useAPIMutation("equipment/", {
    onSuccess: (data) => {
      navigate(`/equipment/${data.id}`);
    },
  });

  const onSubmit = (data) => {
    const buy_date = data.buy_date;
    if (buy_date === "") {
      data = { ...data, buy_date: null };
    }
    mutation.mutate(data);
  };

  return (
    <div className="row">
      <div className="col-4">
        <EquipmentCreateForm
          onSubmit={onSubmit}
          disabled={mutation.isLoading}
        />
      </div>
    </div>
  );
}
