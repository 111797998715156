import { useForm } from "react-hook-form";
import { KT } from "../../utils/regex";
import { useAPIMutation } from "../../hooks/api-hooks";

export default function ContactForm({
  disabled,
  contact = {},
  onSubmit,
  error,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues: contact });

  const contactKt = watch("kt");

  const mutation = useAPIMutation(`customers/${contactKt}`, {
    method: "get",
    onSuccess: (customerData) => {
      if (customerData?.name) {
        setValue("name", customerData.name);
      }
    },
  });

  const isDisabled = disabled;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Nafn"
          {...register("name", { required: true })}
          disabled={isDisabled || mutation.isLoading}
        />
        <label>Nafn</label>
        {errors.name && <p className="alert alert-warning">Nafn vantar</p>}
      </div>
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Kennitala"
          {...register("kt", { pattern: KT })}
          disabled={isDisabled || mutation.isLoading}
          onBlur={() => mutation.mutate()}
        />
        <label>Kennitala</label>
        {errors.kt && (
          <p className="alert alert-warning">Kennitalan er ógild</p>
        )}
      </div>
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Farsími"
          {...register("mobile")}
          disabled={isDisabled}
        />
        <label>Farsími</label>
      </div>
      <div className="form-floating mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Tölvupóstfang"
          {...register("email")}
          disabled={isDisabled}
        />
        <label>Tölvupóstfang</label>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-success" type="submit" disabled={isDisabled}>
          Vista
        </button>
      </div>
    </form>
  );
}
