import { priorities } from "../utils/priority";

export default function Priority({ priority }) {
  const classNames = [
    "text-bg-dark",
    "text-bg-secondary",
    "text-bg-primary",
    "text-bg-warning",
    "text-bg-danger",
  ];

  const clsName = classNames[priority];

  return <span className={"badge " + clsName}>{priorities[priority]}</span>;
}
