import { Link } from "react-router-dom";
import Priority from "../../components/Priority";
import { formatDateTime } from "../../utils/datetime";
import EntryId from "../../components/EntryId";
import InteractionIcons from "./InteractionIcons";

export default function TicketCard({ ticket, interactions = false }) {
  return (
    <div className="border bg-white p-3 mb-2" draggable>
      <div className="d-flex">
        <div>
          <Link to={`/tickets/${ticket.id}`}>
            <EntryId entryId={ticket.id} />
          </Link>
        </div>
        {ticket.invoice_count > 0 && (
          <div className="ms-1">
            <i className="bi bi-file-text"></i>
          </div>
        )}
        <div className="ms-auto">
          {interactions && (
            <InteractionIcons interactions={ticket.interactions} />
          )}
          <Priority priority={ticket.priority} />
        </div>
      </div>
      <div className="fs-5">
        {ticket.equipment.manufacturer.name} {ticket.equipment.identifier}{" "}
        {(ticket.equipment.registration_no || ticket.equipment.serial_no) && (
          <span style={{ border: "1px solid gray" }}>
            {ticket.equipment.registration_no || ticket.equipment.serial_no}
          </span>
        )}
      </div>
      <div>{ticket.service_description.substring(0, 100)}</div>
      <div className="d-flex fs-6 fw-light">
        <div style={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
          {ticket.customer.name}
        </div>
        <div className="ms-auto">
          {formatDateTime(ticket.created_at, false)}
        </div>
      </div>
    </div>
  );
}
