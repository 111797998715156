import { useAPIQuery } from "../../hooks/api-hooks";
import ServiceLocationnavBar from "./ServiceLocationsNavBar";

export default function ServiceLocations() {
  const query = useAPIQuery("service_locations/", {
    queryKey: "servicelocations",
  });

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-geo-alt-fill"></i> Móttökustaðir
      </h1>
      <ServiceLocationnavBar />
      <table className="table mt-4">
        <thead>
          <tr>
            <th>Kóti</th>
            <th>Heiti</th>
            <th>Heimilisfang</th>
            <th>Póstnúmer</th>
          </tr>
        </thead>
        <tbody>
          {query.isLoading && (
            <tr>
              <td>Hleður...</td>
            </tr>
          )}
          {query.isSuccess &&
            query.data.map((location) => (
              <tr key={location.id}>
                <td>{location.id}</td>
                <td>{location.name}</td>
                <td>{location.address}</td>
                <td>
                  {location.post_code} {location?.post_code_entry?.name}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
