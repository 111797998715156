import { Link } from "react-router-dom";

export default function ContactList({ contacts }) {
  return (
    <table className="table mt-4">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Sími</th>
          <th>Tölvupóstfang</th>
          <th>Viðskiptavinur</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => (
          <tr key={contact.id}>
            <td>{contact.name}</td>
            <td>{contact.kt}</td>
            <td>{contact.mobile}</td>
            <td>{contact.email}</td>
            <td>
              <Link to={`/customers/${contact.customer.kt}`}>
                {contact.customer.name}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
