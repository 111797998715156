import { useAPIQuery } from "../hooks/api-hooks";
import { useForm, Controller } from "react-hook-form";
import { KTInput } from "./KTInput";

export default function EquipmentCreateForm({
  customer_kt = null,
  onSubmit,
  disabled = false,
  equipment = {},
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues: equipment });

  const mfgQuery = useAPIQuery("manufacturers/", {
    queryKey: "manufacturers",
    refetchOnWindowFocus: false,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {!customer_kt && (
        <>
          <Controller
            control={control}
            name="customer_kt"
            render={({ field: { onChange, value } }) => (
              <KTInput value={value} onChange={onChange} />
            )}
            rules={{ required: "Viðskiptavin vantar" }}
          />
          {errors.customer_kt && (
            <div className="alert alert-warning">
              {errors.customer_kt.message}
            </div>
          )}
        </>
      )}

      <div className="form-floating mb-3">
        {mfgQuery.isLoading ? (
          <select id="loading" className="form-control" disabled></select>
        ) : (
          <select
            className="form-select"
            disabled={mfgQuery.isLoading || disabled}
            {...register("manufacturer_id", {
              required: true,
              pattern: /^\d+$/,
            })}
          >
            <option value={null}>Veldu...</option>
            {mfgQuery.isSuccess &&
              mfgQuery.data.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </option>
              ))}
          </select>
        )}
        <label>Framleiðandi</label>
        {errors.manufacturer && (
          <p className="alert alert-warning">Framleiðanda vantar</p>
        )}
      </div>

      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Heiti vöru eða auðkenni"
          {...register("identifier", { required: true })}
          disabled={disabled}
        />
        <label>Heitir vöru eða auðkenni</label>
        {errors.identifier && (
          <p className="alert alert-warning">Auðkenni vantar</p>
        )}
      </div>

      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Raðnúmer"
          {...register("serial_no")}
          disabled={disabled}
        />
        <label>Raðnúmer</label>
      </div>

      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Vörunúmer"
          {...register("product_id")}
          disabled={disabled}
        />
        <label>Vörunúmer</label>
      </div>

      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Skráningarnúmer farartækis / vinnuvélar"
          {...register("registration_no")}
          disabled={disabled}
        />
        <label>Skráningarnúmer farartækis / vinnuvélar</label>
      </div>

      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Kaupdasetning (áááá-mm-dd)"
          {...register("buy_date", {
            required: false,
            pattern: {
              value: /^[12][09]\d{2}-[01]\d-[0123]\d$/,
              message: "Dagsetning verður að vera á forminu áááá-mm-dd",
            },
          })}
          disabled={disabled}
        />
        <label>Kaupdagsetning (áááá-mm-dd)</label>
      </div>

      <div className="form-floating mb-3">
        <textarea
          className="form-control"
          placeholder="Nánari lýsing, aukabúnaður, sjáanlegar skemmdir o.sv.frv."
          {...register("description")}
          style={{ height: "8em" }}
          disabled={disabled}
        />
        <label>Nánari lýsing, aukabúnaður, sjáanlegar skemmdir o.sv.frv.</label>
      </div>

      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" disabled={disabled}>
          Vista
        </button>
      </div>
    </form>
  );
}
