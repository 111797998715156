import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "../../hooks/api-hooks";
import { useQueryClient } from "react-query";

export default function CreateInvoiceDialog({ show, ticketId, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { id: "" } });

  const close = () => {
    reset();
    onClose();
  };

  const onSubmit = (data) => {
    mutate({ ...data, ticket_id: ticketId });
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useAPIMutation(
    "tickets/invoices/",
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["ticket", ticketId], (oldTicket) => ({
          ...oldTicket,
          invoices: [...oldTicket.invoices, data],
        }));
        close();
      },
    }
  );

  return (
    <Modal show={show} onHide={close} animation={false} centered>
      <Modal.Header closeButton>Skrá reikning</Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{error.message}</div>}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Númer reiknings"
              autoComplete="off"
              disabled={isLoading}
              {...register("id", { required: "Númer reiknings vantar" })}
            />
            <label>Númer reiknings</label>
          </div>
          {errors && errors?.id && (
            <div className="alert alert-warning">{errors.id.message}</div>
          )}
          <div>
            <button className="btn btn-success" disabled={isLoading}>
              Vista
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
