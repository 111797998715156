import { useAPIQuery } from "../../hooks/api-hooks";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function ContactList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") ?? 0);
  const query = useAPIQuery("contacts/", {
    queryKey: ["contacts", offset],
    params: { offset },
  });

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  return (
    <div>
      <table className="table mt-4">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Sími</th>
            <th>Tölvupóstfang</th>
            <th>Viðskiptavinur</th>
          </tr>
        </thead>
        <tbody>
          {query.isSuccess &&
            query.data.map((contact) => (
              <tr key={contact.id}>
                <td>
                  <Link to={`/contacts/${contact.id}`}>{contact.name}</Link>
                </td>
                <td>{contact.kt}</td>
                <td>{contact.mobile}</td>
                <td>{contact.email}</td>
                <td>
                  <Link to={`/customers/${contact.customer.kt}`}>
                    {contact.customer.name}
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex">
        <div>
          <Button
            disabled={offset === 0}
            onClick={() => setSearchParams({ offset: offset - 20 })}
          >
            <i className="bi bi-chevron-left"></i> Fyrri
          </Button>
        </div>
        <div className="ms-auto">
          <Button onClick={() => setSearchParams({ offset: offset + 20 })}>
            Næsta <i className="bi bi-chevron-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
}
