import { useParams } from "react-router-dom";
import { useAPIQuery } from "../../hooks/api-hooks";
import Detail from "../../components/Detail";
import { Link } from "react-router-dom";
import IntakeList from "../customers/IntakeList";
import TicketList from "../../components/TicketList";

export default function ContactDetail() {
  const { id } = useParams();

  const query = useAPIQuery(`contacts/${id}`, { queryKey: ["contact", id] });

  if (query.isLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Hleður...</span>
      </div>
    );
  }

  if (query.isError) {
    return (
      <div className="alert alert-warning">{JSON.stringify(query.error)}</div>
    );
  }

  return (
    <div className="d-flex gap-2">
      <div style={{ minWidth: "400px" }}>
        <div className="bg-white border p-3 mb-3">
          <h3>Tengiliður</h3>
          <Detail title="Heiti" value={query.data.name} />
          <Detail title="Kennitala" value={query.data.kt} />
          <Detail
            title="Tölvupóstfang"
            value={
              <a href={"mailto:" + query.data.email}>{query.data.email}</a>
            }
          />
          <Detail
            title="Sími"
            value={
              <a href={"tel:" + query.data?.mobile}>{query.data?.mobile}</a>
            }
          />
          <Detail
            title="Viðskiptavinur"
            value={
              <Link to={`/customers/${query.data.customer_kt}`}>
                {query.data.customer.name}
              </Link>
            }
          />
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="d-flex justify-content-end">
          <div>
            <Link className="btn btn-primary" to={`/contacts/edit/${id}`}>
              Breyta tengilið
            </Link>
          </div>
        </div>
        <h3>Verk</h3>
        <TicketList tickets={query.data.customer.tickets} />
        <h3>Móttökur</h3>
        <IntakeList intakes={query.data.customer.intakes} />
      </div>
    </div>
  );
}
