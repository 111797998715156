import { Outlet } from "react-router-dom";

export default function Contacts() {
  return (
    <>
      <h1 className="mb-4">
        <i className="bi bi-person pe-3"></i>Tengiliðir
      </h1>
      <Outlet />
    </>
  );
}
